<template>
  <div>

    <van-cell title="开始" :value="beginDate" @click="show1=true" is-link v></van-cell>
    <van-cell title="结束" :value="endDate" value-class="" is-link @click="show2=true"></van-cell>

    <van-list v-model="listLoading" @load="loadPerList" :finished="listFinished" :immediate-check="false" :offset="30" finished-text="没有更多了">
      <van-swipe-cell v-for="(item ,index) in listData" :key="index" :name="index">
        <el-card style="margin:5px;background-color:#67C23A">
          <van-cell title="试验时间" :value="item.createTime"></van-cell>
          <van-cell title="击实次数" :value="item.jiShiCiShu"></van-cell>
          <van-cell title="击实重量" :value="item.jiShiZhongLiang"></van-cell>
          <van-cell title="击实直径" :value="item.jiShiZhiJing"></van-cell>
        </el-card>
        <template #right>
          <van-button type="info" square text="编辑" class="delete-button" :to="'/WapJSY002Detail?id='+item.id" />
          <van-button type="danger" square text="删除" class="delete-button" @click="deleteModel(index)" />
        </template>
      </van-swipe-cell>
    </van-list>
    <van-calendar v-model="show1" @confirm="onConfirm1" :min-date="minDate" :max-date="maxDate"></van-calendar>
    <van-calendar v-model="show2" @confirm="onConfirm2" :min-date="minDate" :max-date="maxDate"></van-calendar>
  </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
import Moment from "moment";
Vue.use(Vant);
export default {
  props: ["serialNumber"],
  data() {
    return {
      show1: false,
      show2: false,
      model32: { YunXingZhi: 0, SheZhiZhi: 0 },
      beginDate: "",
      endDate: "",
      pageSize: 5,
      currentPage: 1,
      listLoading: false,
      listData: [],
      listFinished: false,
      minDate: new Date(2020, 1, 1),
      maxDate: new Date(2030, 1, 1),
    };
  },
  methods: {
    onConfirm1(v) {
      let that = this;
      that.show1 = false;
      that.beginDate = Moment(v).format("yyyy-MM-DD");
      that.loadList();
    },
    onConfirm2(v) {
      let that = this;
      that.show2 = false;
      that.endDate = Moment(v).format("yyyy-MM-DD");
      that.loadList();
    },
    deleteModel(index) {
      let that = this;
      that.$dialog.confirm({ message: "确定要删除吗？" }).then(function () {
        that.axios
          .post("JSY002/DeleteModel", {
            onlyInt: that.listData[index].id,
          })
          .then(function () {
            that.listData.splice(index, 1);
          });
      });
    },
    loadList() {
      let that = this;
      //that.listLoading = false;
      that.listLoading = true;
      that.listFinished = false;
      that.listData.length = 0;
      that.currentPage = 1;
      that.loadPerList();
    },
    loadPerList() {
      let that = this;
      that.axios
        .post("JSY002/GetList", {
          beginDate: that.beginDate,
          endDate: that.endDate,
          pageSize: that.pageSize,
          currentPage: that.currentPage,
        })
        .then(function (response) {
          response.data.data.data.forEach((element) => {
            that.listData.push(element);
          });
          that.listLoading = false;
          if (that.listData.length >= response.data.data.total) {
            that.listFinished = true;
          } else {
            that.currentPage++;
          }
        });
    },
  },
  mounted() {
    let that = this;
    that.loadPerList();
  },
};
</script>

<style scoped>
.delete-button {
  height: 100%;
}
</style>